import { Component, lazy } from "react";
import { permissions, roles } from "../services/roles";

export const publicRoutes = [
  {
    path: "/auth/login",
    Component: lazy(() => import("../views/auth/login")),
    exact: true,
  },
  {
    path: "/auth/password/forgot",
    Component: lazy(() => import("../views/auth/forgot-password")),
    exact: true,
  },
  {
    path: "/auth/password/reset",
    Component: lazy(() => import("../views/auth/reset-password")),
    exact: true,
  },
  {
    path: "/auth/password/first-login",
    Component: lazy(() => import("../views/auth/first-login")),
    exact: true,
  },
];

export const privateRoutes = [
  {
    path: "/analytics",
    Component: lazy(
      () => import("../views/company/internet-management/lines-monitoring")
    ),
    exact: true,
  },
  {
    path: "/settings",
    Component: lazy(() => import("../views/settings")),
    exact: false,
  },
  {
    path: "/companies",
    Component: lazy(() => import("../views/companies")),
    exact: false,
  },
  {
    path: "/billing",
    Component: lazy(() => import("../views/billing")),
    exact: false,
    roles: permissions.billing,
  },

  {
    path: "/company/:companyId",
    Component: lazy(() => import("../views/company/company-profile")),
    exact: true,
  },
  {
    path: "/company/:companyId/basic-info",
    Component: lazy(
      () => import("../views/company/company-basic-info/edit-existing-company")
    ),
    exact: true,
    roles: permissions.companyBasicInfo,
  },
  {
    path: "/company/:companyId/company-features",
    Component: lazy(() => import("../views/company/comapny-features")),
    exact: true,
    roles: permissions.companyBasicInfo,
    // TODO : update the roles 
  },
  {
    path: "/company/:companyId/branch/:branchId/:branchName",
    Component: lazy(() => import("../views/branch")),
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/add-new-company/basic-info",
    Component: lazy(
      () => import("../views/company/company-basic-info/add-new-company")
    ),
    exact: true,
    roles: permissions.companyBasicInfo,
  },
  {
    path: "/company/:companyId/branch",
    Component: lazy(() => import("../views/company/branches")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/branch/add",
    Component: lazy(() => import("../views/branch/add-edit-branch")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/branch/:branchId",
    Component: lazy(() => import("../views/branch/add-edit-branch")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/subscription-plan",
    Component: lazy(() => import("../views/company/subscription-plan")),
    exact: true,
    roles: permissions.subscriptionPlan,
  },
  {
    path: "/company/:companyId/billing",
    Component: lazy(() => import("../views/company/billing")),
    exact: true,
    roles: permissions.billing,
  },
  {
    path: "/company/:companyId/billing/transactions-history",
    Component: lazy(
      () => import("../views/company/billing/transactions-history")
    ),
    exact: true,
    roles: permissions.billing,
  },
  {
    path: "/company/:companyId/team",
    Component: lazy(() => import("../views/company/company-team")),
    exact: false,
    roles: permissions.viewTeamMembersOfTheCompany,
  },
  {
    path: "/doorAccess",
    Component: lazy(() => import("../views/doorAccess/index")),
    exact: true,
    roles: permissions.doorAccess,
  },
  {
    path: "/management/line-details/:branchId/:portName/:companyId",
    Component: lazy(
      () =>
        import(
          "../views/company/internet-management/lines-monitoring/line-details/index"
        )
    ),
    exact: true,
    roles: permissions.doorAccess,
  },
];

export const settingsRoutes = [
  {
    path: "/settings/team-members",
    Component: lazy(() => import("../views/team-members/index")),
    exact: false,
    roles: permissions.staffModule,
  },
];

export const companiesRoutes = [
  {
    path: "/companies/existing",
    Component: lazy(() => import("../views/companies/existing/index")),
    exact: true,
  },
  {
    path: "/companies/pending-activation",
    Component: lazy(() => import("../views/companies/pending-activation")),
    exact: true,
  },
  {
    path: "/companies/non-verified",
    Component: lazy(() => import("../views/companies/none-verified")),
    exact: true,
  },
];

export const branchRoutes = [
  {
    path: "/company/:companyId/branch/:branchId/:branchName/phone-lines",
    Component: lazy(() => import("../views/branch/phone-lines")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/branch/:branchId/:branchName/routers",
    Component: lazy(() => import("../views/branch/routers")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/branch/:branchId/:branchName/pihole",
    Component: lazy(() => import("../views/branch/pihole")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/branch/:branchId/:branchName/switches",
    Component: lazy(() => import("../views/branch/switches")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/branch/:branchId/:branchName/access-points",
    Component: lazy(() => import("../views/branch/access-points")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
  {
    path: "/company/:companyId/branch/:branchId/:branchName/edge-server",
    Component: lazy(() => import("../views/branch/edge-server")),
    exact: true,
    roles: permissions.branchesAndAssets,
  },
];
